<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="elevation-1">
      <div class="d-flex flex-row justify-center align-center">
        <h3>{{ service.title }}</h3>
        <v-spacer />
        <!--        <v-btn-->
        <!--          small-->
        <!--          rounded-->
        <!--          outlined-->
        <!--          color="red"-->
        <!--          class="mr-2"-->
        <!--          :loading="refreshing"-->
        <!--          @click.stop="restartApp"-->
        <!--        >-->
        <!--          Restart-->
        <!--        </v-btn>-->
      </div>
    </v-expansion-panel-header>

    <v-expansion-panel-content>
      <div class="pa-2 lighten-3 d-flex flex-row align-center justify-center">
        <template v-if="canCreate">
          <v-btn color="primary" @click="newForm = true"> <v-icon>mdi-plus</v-icon> Add New </v-btn>
          <v-spacer />
        </template>
        <v-text-field
          v-model="filterText"
          solo
          hide-details
          clearable
          name="filter"
          autocomplete="filter"
          placeholder="Filter configs"
          prepend-inner-icon="mdi-magnify"
        />
      </div>
      <v-divider />
      <v-list class="text-left">
        <template v-for="(config, i) in filteredConfigs">
          <v-divider :key="config.id" v-if="i" />
          <ApplicationSettingItem
            :key="service.id + config.id"
            :config="config"
            @change="valueChanged"
          />
        </template>
        <div
          class="text-center grey--text py-15"
          v-if="!filteredConfigs || !filteredConfigs.length"
        >
          No settings available
          <span v-if="filterText">
            for <b>"{{ filterText }}"</b>
          </span>
        </div>
      </v-list>
    </v-expansion-panel-content>

    <v-dialog v-model="newForm" max-width="500">
      <NewConfigForm :applicationP="service.id" @change="valueChanged" />
    </v-dialog>
  </v-expansion-panel>
</template>

<script>
import { ACTUATOR_ROUTES_REFRESH } from "@/constants/api";

export default {
  name: "ApplicationSetting",
  components: {
    NewConfigForm: () => import("@/views/components/Management/NewConfigForm"),
    ApplicationSettingItem: () => import("@/views/components/Management/ApplicationSettingItem"),
  },
  props: {
    service: {
      type: Object,
      required: true,
    },
    configs: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    filterText: null,
    refreshing: false,
    newForm: false,
  }),
  computed: {
    filteredConfigs() {
      const filter = (this.filterText || "").trim().toLowerCase();
      if (!filter) return this.configs;
      return this.configs.filter((item) => (item.name + item.value).toLowerCase().includes(filter));
    },
    canCreate() {
      return this.verifyPrivilege("/api/config/create/*/*/*/*", "POST");
    },
  },
  methods: {
    async valueChanged(item) {
      try {
        this.newForm = false;
        this.refreshing = true;
        await this.$axios.post(this.service.prefix + ACTUATOR_ROUTES_REFRESH);
        this.$iziToast.success({
          title: "Success",
          message: `${this.service.title} has been restarted`,
        });
        this.$emit("change", item);
      } catch (err) {
        this.$iziToast.showError(err);
      } finally {
        this.refreshing = false;
      }
    },
  },
};
</script>
